import { AppRoute, createImmerseBaseUrl } from 'constants/AppRoute';
import { TestLocators } from 'constants/Locators';

import { EDatabaseName } from 'helpers/constants';
import { translations } from 'locales/i18n';

export const enum NON_ROUTE_HEADER_IDS {
  HELP = 'help',
  DOWNLOADS = 'downloads',
}

export const nonRouteHeaderIds: string[] = [NON_ROUTE_HEADER_IDS.HELP, NON_ROUTE_HEADER_IDS.DOWNLOADS];

type THeaderItem = {
  id: string;
  label: string;
  testId: TestLocators;
  isDisabled: boolean;
};

export const getHeaderItemsList = (
  selectedDatabaseFromRedux: EDatabaseName | '',
  isPlanPredictEnabled: boolean,
  isSqlEditorEnabled: boolean,
): THeaderItem[] => {
  const standardHeaderList = [
    {
      id: AppRoute.ROOT,
      label: translations.HOME.TITLE,
      testId: TestLocators.Header_Home,
      isDisabled: false,
    },
    {
      id: `${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.DASHBOARDS}`,
      label: translations.DASHBOARDS.TITLE,
      testId: TestLocators.Header_Dashboards,
      isDisabled: false,
    },
    {
      id: `${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.DATA_MANAGER}`,
      label: translations.DATA_MANAGER.TITLE,
      testId: TestLocators.Header_DataManager,
      isDisabled: false,
    },

    // {
    //   id: `${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.HEAVY_IQ}`,
    //   label: translations.HEAVY_IQ.TITLE,
    //   testId: TestLocators.Header_Heavy_IQ,
    //   isDisabled: false,
    // },

    {
      id: AppRoute.PRODUCTION_PREDICTION,
      isDisabled: !isPlanPredictEnabled,
      testId: isPlanPredictEnabled ? TestLocators.Header_Predict_Enabled : TestLocators.Header_Predict_Disabled,
      label: translations.PRODUCTION_PREDICTION.TITLE,
    },

    {
      id: AppRoute.WELL_PLANNER,
      isDisabled: !isPlanPredictEnabled,
      testId: isPlanPredictEnabled ? TestLocators.Header_Plan_Enabled : TestLocators.Header_Plan_Disabled,
      label: translations.WELL_PLANNER.TITLE,
    },

    {
      isDisabled: !isPlanPredictEnabled,
      id: AppRoute.ECONOMICS,
      testId: isPlanPredictEnabled ? TestLocators.Header_Economics_Enabled : TestLocators.Header_Economics_Disabled,
      label: translations.ECONOMICS.TITLE,
    },

    {
      id: NON_ROUTE_HEADER_IDS.HELP,
      label: translations.HELP.TITLE,
      testId: TestLocators.Header_Help,
      isDisabled: false,
    },
    {
      id: NON_ROUTE_HEADER_IDS.DOWNLOADS,
      label: translations.DOWNLOAD_SHAPEFILE.TITLE,
      testId: TestLocators.Header_Downloads,
      isDisabled: false,
    },
  ];

  return isSqlEditorEnabled
    ? [
        ...standardHeaderList.slice(0, 3),
        {
          id: `${createImmerseBaseUrl(selectedDatabaseFromRedux)}${AppRoute.SQL_EDITOR}`,
          isDisabled: !isSqlEditorEnabled,
          label: translations.SQL_EDITOR.TITLE,
          testId: TestLocators.Header_GreyedOut_SQLEditor,
        },
        ...standardHeaderList.slice(3),
      ]
    : standardHeaderList;
};
