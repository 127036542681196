import { TestLocators } from 'constants/Locators';

import { FormGroupButtonType } from 'app/components/common/ButtonsFormGroup/types';
import { translations } from 'locales/i18n';

// import { Calculate } from './Calculate';
import { Manual } from './Manual';
import { SpEstimate } from './SpEstimate';

export const wellDesignCapexTitle = translations.CONFIG_PANEL.ECONOMICS.CONTROL.WELL_DESIGN_AND_CAPEX.BUTTONS;

export const enum EWellDesignCapexOption {
  SP_ESTIMATE = 'spEstimate',
  CALCULATE = 'calculate',
  MANUAL = 'manual',
}

export const wellDesignCapexTabs = [
  {
    id: EWellDesignCapexOption.SP_ESTIMATE,
    title: wellDesignCapexTitle.SP_ESTIMATE,
    component: <SpEstimate key={wellDesignCapexTitle.SP_ESTIMATE.TITLE} />,
  },
  // {
  //   id: EWellDesignCapexOption.CALCULATE,
  //   title: wellDesignCapexTitle.CALCULATE,
  //   component: <Calculate key={wellDesignCapexTitle.CALCULATE.TITLE} />,
  // },
  {
    id: EWellDesignCapexOption.MANUAL,
    title: wellDesignCapexTitle.MANUAL,
    component: <Manual key={wellDesignCapexTitle.MANUAL.TITLE} />,
  },
];

export const buttons: FormGroupButtonType[] = [
  {
    id: EWellDesignCapexOption.SP_ESTIMATE,
    buttonName: wellDesignCapexTitle.SP_ESTIMATE.LABEL,
    headerTestId: TestLocators.Economics_SP_Estimate_Btn,
    title: wellDesignCapexTitle.SP_ESTIMATE.TITLE,
  },
  // {
  //   id: EWellDesignCapexOption.CALCULATE,
  //   buttonName: wellDesignCapexTitle.CALCULATE.LABEL,
  //   headerTestId: TestLocators.Economics_Calculate_Btn,
  //   title: wellDesignCapexTitle.CALCULATE.TITLE,
  // },
  {
    id: EWellDesignCapexOption.MANUAL,
    buttonName: wellDesignCapexTitle.MANUAL.LABEL,
    headerTestId: TestLocators.Economics_Manual_Btn,
    title: wellDesignCapexTitle.MANUAL.TITLE,
  },
];
