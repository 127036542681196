import { RequestStatus } from 'constants/RequestStatus';

import { Button, Dialog, List, ListItem, Typography } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Loader } from 'impact-react-components';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { shapeFileDownloadClick as trackEvent } from 'common/analytics';
import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';
import { colors } from 'styles/theme/colors';
import { downloadFileByUrl } from 'utils/download';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { selectData, selectError, selectRegion, selectStatus } from '../selectors';
import { landingPageShapefileSaga } from './saga';
import { selectFileUrl, selectFilename } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { useStyles } from './styles';

export interface ShapefileModalProps {
  isOpen: boolean;
  onClose(): void;
}

export function ShapefileModal({ isOpen, onClose }: ShapefileModalProps) {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: landingPageShapefileSaga });
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const shapefiles = useSelector(selectData);
  const region = useSelector(selectRegion);
  const fileUrl = useSelector(selectFileUrl);
  const fileName = useSelector(selectFilename);

  const handleDownload = useCallback(
    (filename: string) => {
      trackEvent(region ?? '', fileName);
      dispatch(actions.loadShapefile({ region, filename }));
      onClose();
    },
    [dispatch, fileName, onClose, region],
  );

  const renderList = useMemo(() => {
    return shapefiles.map((item: string) => (
      <ListItem key={item} className={classes.listItem}>
        <Typography variant="h2" component="span" className={classes.itemText}>
          {item}
        </Typography>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: colors.primary, color: colors.primaryWhite }}
          onClick={() => handleDownload(item)}
        >
          {t(`OPERATIONS.DOWNLOAD`)}
        </Button>
      </ListItem>
    ));
  }, [shapefiles, classes, handleDownload, t]);

  useEffect(() => {
    if (fileUrl !== null) {
      downloadFileByUrl(fileUrl);
      log.info({
        event: LogEvent.DOWNLOAD,
        source: LogSource.SHAPEFILE_DOWNLOAD,
        fileName: fileName,
        region: region,
      });
      dispatch(actions.resetFilename());
    }
  }, [dispatch, fileName, fileUrl, region]);

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} onClose={onClose} open={isOpen}>
      <Typography variant="h2" className={classes.dialogHeader}>
        {`${t(`DOWNLOAD_SHAPEFILE.MODAL_TITLE`)} - ${t(`DOWNLOAD_SHAPEFILE.ITEMS.${region}`)}`}
      </Typography>
      <MuiDialogContent className={classes.dialogContent} dividers>
        {status === RequestStatus.LOADING && <Loader />}
        {status === RequestStatus.FAILURE && (error as ReactNode) && (
          <div className={classes.error}>
            <Typography>{t('DOWNLOAD_SHAPEFILE.ERROR')}</Typography>
          </div>
        )}
        {status === RequestStatus.SUCCESS && <List>{renderList}</List>}
      </MuiDialogContent>
      <MuiDialogActions className={classes.dialogFooter}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          className={classes.cancel}
          autoFocus
          onClick={onClose}
        >
          {t('COMMON.CANCEL')}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
}
